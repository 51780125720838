import domReady from '@roots/sage/client/dom-ready';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

function scrollGallery(el) {
  const gallery = el;

  // If no gallery is found, return
  if (!gallery) return;

  // Get the elements
  const scrollContainer = gallery.querySelectorAll('.scroll-container')[0];
  const scrollLeftBtn = gallery.querySelectorAll('.arrow-previous')[0];
  const scrollRightBtn = gallery.querySelectorAll('.arrow-next')[0];
  const items = gallery.querySelectorAll('.wp-block-post');
  const scrollAmount = items[0].offsetWidth;

  // Scroll Buttons Event Listeners
  scrollLeftBtn.addEventListener('click', () => {
    scrollContainer.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  });

  scrollRightBtn.addEventListener('click', () => {
    scrollContainer.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  });
}

function scrollTriggers(el) {
  // If no element is found, return
  if (!el) return;

  // check if the user doesn't prefer reduced motion
  const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

  // If the user prefers reduced motion, don't animate the gallery
  if (!prefersReducedMotion) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        scrub: true,
        start: 'bottom bottom',
        end: 'bottom -=400',
        markers: false,
      },
    });

    tl.to(
      el,
      {
        x: '-=300',
        ease: 'power1.inOut',
      },
      0,
    );
  }
}

domReady(async () => {
  const galleries = document.querySelectorAll('.wp-block-gca-block-scroller.has-navigation');
  galleries.forEach(scrollGallery);

  const triggers = document.querySelectorAll('.wp-block-gca-block-scroller.horizontal-scroll-trigger');

  //load the scroll trigger plugin
  if (triggers.length) {
    gsap.registerPlugin(ScrollTrigger);
    triggers.forEach(scrollTriggers);
  }
});
